import React, { useEffect } from 'react'
import useSpotBuy from 'hooks/useSpotBuy';
import List from './list';
function SpotSellList() {

  const {state:spotBuyState,fetchSpotBuys}=useSpotBuy();

  const headers = [
    'No',
    'Date',
    'Coin',
    'Sale Price',
    "Percentage",
    "Status",
    "Actions"
]


  useEffect(()=>{
    fetchSpotBuys({status:"SELL"});
  },[])

  return (
    <List 
    headers={headers}
    data={spotBuyState.spotBuys}
    title="Spot Trading Lists"
    loading={spotBuyState.loading}
    buttons={<></>}
    />
  )
}

export default SpotSellList