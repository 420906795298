import React, { useEffect } from 'react'
import useSpotBuy from 'hooks/useSpotBuy';
import Button from 'components/Button';
import { NotificationManager } from 'react-notifications';

function BinanceOrderList() {
    const { state: spotBuyState, fetchBinanceOrder } = useSpotBuy();



    return (
        <div>
            <Button disabled={spotBuyState.loading} onClick={async ()=>{
                await fetchBinanceOrder();
                NotificationManager.success("You have cancelled all orders");
            }}>Cacnel All New Orders</Button>
        </div>
    )
}

export default BinanceOrderList