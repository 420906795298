import Button from 'components/Button'
import useMember from 'hooks/useMember'
import React, { useEffect } from 'react'
import List from './list';

function WalletLocks() {

    const { state: memberState, lockWallets, fetchLockWallets } = useMember();

    const handleCreateNew = async () => {
        await lockWallets();
    }

    const headers = [
        'No',
        'Date',
        'Status',
        "Actions"
    ]


    useEffect(() => {
        async function loadData() {
            await fetchLockWallets()
        }
        loadData();
    }, [])

    return (
        <div>
          
            <List
                headers={headers}
                data={memberState.lockWallets}
                title="Lock Wallets"
                loading={memberState.loading}
                buttons={<></>}
            />
        </div>
    )
}

export default WalletLocks