import {  getData, sendData } from './apiService'
import { NotificationManager } from 'react-notifications'

export async function getAll(query) {
  try {
    let response = await getData(`spot/orders?${new URLSearchParams(query)}`)
    return response.data
  } catch (e) {
    NotificationManager.error('Opps. Something wrong')
    return false
  }
}

export async function getAllFuture(query) {
  try {
    let response = await getData(`future/orders?${new URLSearchParams(query)}`)
    return response.data
  } catch (e) {
    NotificationManager.error('Opps. Something wrong')
    return false
  }
}



export async function getDetail(id) {
  try {
    let response = await getData('spot/order/'+id)
    return response.data
  } catch (e) {
    NotificationManager.error('Opps. Something wrong')
    return false
  }
}

export async function getFutureDetail(id) {
  try {
    let response = await getData('future/order/'+id)
    return response.data
  } catch (e) {
    NotificationManager.error('Opps. Something wrong')
    return false
  }
}



export async function getSpotPayment(id) {
  try {
    let response = await getData('spot/payment/'+id)
    return response.data
  } catch (e) {
    NotificationManager.error('Opps. Something wrong')
    return false
  }
}



export async function getSaleDetail(id) {
  try {
    let response = await getData('spot/sale-order/'+id)
    return response.data
  } catch (e) {
    NotificationManager.error('Opps. Something wrong')
    return false
  }
}



export async function getEach(id) {
  try {
    let response = await getData('spot-each/'+id)
    return response.data
  } catch (e) {
    NotificationManager.error('Opps. Something wrong')
    return false
  }
}

export async function getBinanceOrders() {
  try {
    let response = await getData("spot/binance-orders");
    return response.data
  } catch (e) {
    NotificationManager.error('Opps. Something wrong')
    return false
  }
}



export async function cancelOrders(values) {
  try {
    let response = await sendData('spot/order-cancel/',values)
    return response.data
  } catch (e) {
    NotificationManager.error('Opps. Something wrong')
    return false
  }
}

export async function deleteOrderService(id) {
  try {
    let response = await sendData('spot/order-delete/',id)
    return response.data
  } catch (e) {
    NotificationManager.error('Opps. Something wrong')
    return false
  }
}

export async function deleteFutureOrderService(id) {
  try {
    let response = await sendData('future/order-delete/',id)
    return response.data
  } catch (e) {
    NotificationManager.error('Opps. Something wrong')
    return false
  }
}

export async function closePositionFutureOrderService(id) {
  try {
    let response = await sendData('future/order-position-delete/',id)
    return response.data
  } catch (e) {
    NotificationManager.error('Opps. Something wrong')
    return false
  }
}






export async function deletSaleOrderService(id) {
  try {
    let response = await sendData('spot/sale-order-delete/',id)
    return response.data
  } catch (e) {
    NotificationManager.error('Opps. Something wrong')
    return false
  }
}

export async function savePaymentService(data) {
  try {
    let response = await sendData('spot/payment-save',data)
    return response.data
  } catch (e) {
    NotificationManager.error('Opps. Something wrong')
    return false
  }
}


export async function giveLockPaymentService(body) {
  try {
    let response = await sendData('spot/save-lock-payment/'+body.id,body)
    return response.data
  } catch (e) {
    NotificationManager.error('Opps. Something wrong')
    return false
  }
}

export async function giveFutureLockPaymentService(body) {
  try {
    let response = await sendData('future/save-lock-payment/'+body.id,body)
    return response.data
  } catch (e) {
    NotificationManager.error('Opps. Something wrong')
    return false
  }
}














export async function getTradingProfit() {
  try {
    let response = await getData('spot/trading-profit/')
    return response.data
  } catch (e) {
    NotificationManager.error('Opps. Something wrong')
    return false
  }
}

export async function getTradingProfitLogs() {
  try {
    let response = await getData('spot/trading-profit-logs')
    return response.data
  } catch (e) {
    NotificationManager.error('Opps. Something wrong')
    return false
  }
}

export async function getReducePayments(id) {
  try {
    let response = await getData('spot/reduce-payments/'+id)
    return response.data
  } catch (e) {
    NotificationManager.error('Opps. Something wrong')
    return false
  }
}

export async function getReduceChildPayments(id) {
  try {
    let response = await getData('spot/reduce-child-payments/'+id)
    return response.data
  } catch (e) {
    NotificationManager.error('Opps. Something wrong')
    return false
  }
}



