import React from 'react'
import EntryGrid from 'components/Inputs/EntryGrid'
import { Col, FormGroup, Row, Form } from 'reactstrap'
import Button from 'components/Button'
import TextBox from 'components/Inputs/TextBox'
import { useForm } from 'react-hook-form'

import useMember from 'hooks/useMember'
import CheckBox from 'components/Inputs/CheckBox'
import useAdmin from 'hooks/useAdmin'
function InputForm({ title, data }) {
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
    watch,
    setValue,
  } = useForm()

  const {currentAdmin}=useAdmin();

  React.useEffect(() => {
    if (data) {
      setValue('name', data.name)
      setValue('email', data.email)
      setValue('allowNoRefer', data.allowNoRefer)
      setValue('password', data.password)
    }
  }, [data, setValue])

  const { storeMember, updateMember } = useMember()

  const submitData = async (values) => {
    reset()
    console.log(values)
    data ? await updateMember(data.id, values) : await storeMember(values)
  }

  return (
    <EntryGrid title={title}>
      <Form onSubmit={handleSubmit(submitData)}>
        <div className="pt-2 pb-2">
          <Row>
            <Col md="4">
              <FormGroup>
                <label>Name</label>
                <TextBox
                  className="form-control"
                  placeholder="Name"
                  type="text"
                  registerProps={register('name', {
                    required: 'Name is required',
                  })}
                  errors={errors.name}
                />
              </FormGroup>
            </Col>
            <Col md="4">
              <FormGroup>
                <label>Email</label>
                <TextBox
                  className="form-control"
                  placeholder="Email"
                  type="text"
                  registerProps={register('email', {
                    required: 'Email is required',
                  })}
                  readOnly={data}
                  errors={errors.email}
                />
              </FormGroup>
            </Col>
            <Col md="4">
              <FormGroup>
                <label>Allow No Refer</label>
                <CheckBox
                  label="yes"
                  checked={watch('allowNoRefer') === 1 ? true : false}
                  onChange={() => {
                    if (watch('allowNoRefer') === 1) setValue('allowNoRefer', 0)
                    else setValue('allowNoRefer', 1)
                  }}
                />
              </FormGroup>
            </Col>
            {!data && (
              <Col md="4">
                <FormGroup>
                  <label>Password</label>
                  <TextBox
                    className="form-control"
                    placeholder="Password"
                    type="text"
                    registerProps={register('password', {
                      required: 'Password is required',
                    })}
                    errors={errors.password}
                  />
                </FormGroup>
              </Col>
            )}
          </Row>
          {currentAdmin?.role==="admin" && <div className="mt-5 text-center">
            <Button>Save</Button>
            <Button
              className="ml-2"
              onClick={() => {
                reset()
              }}
            >
              Reset
            </Button>
          </div>}
        </div>
      </Form>
    </EntryGrid>
  )
}

export default InputForm
