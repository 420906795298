import React from 'react'
import {
  getAll,
  getAllFuture,
  getEach,
  getDetail,
  getFutureDetail,
  getSaleDetail,
  getBinanceOrders,
  cancelOrders,
  deleteOrderService,
  deleteFutureOrderService,
  deletSaleOrderService,
  getSpotPayment,
  savePaymentService,
  closePositionFutureOrderService,
  giveLockPaymentService,
  giveFutureLockPaymentService,
  getTradingProfit,
  getTradingProfitLogs,
  getReducePayments,
  getReduceChildPayments,
} from 'services/spotBuyService'

const initialState = {
  loading: false,
  actionLoading: false,
  total: 0,
  spotPayments: [],
  futureOrders:[],
  binanceOrders: [],
  futureOrderDetails:[],
  tradingProfitLogs: [],
  tradingProfit: {},
  reducePayments: [],
  reduceChildPayments: [],
}

function reducer(state, action) {
  switch (action.type) {
    case 'SET_SPOT_BUYS':
      return {
        ...state,
        spotBuys: action.payload.data,
      }

    case 'SET_SPOT_BUY':
      return {
        ...state,
        spotBuy: action.payload.data,
      }


    case 'SET_FUTURE_ORDERS':
      return {
        ...state,
        futureOrders: action.payload.data,
      }

      

    case 'SET_SPOT_BUY_DETAIL':
      return {
        ...state,
        spotBuyDetails: action.payload.data,
      }

      case 'SET_FUTURE_DETAIL':
      return {
        ...state,
        futureOrderDetails: action.payload.data,
      }

      

    case 'SET_SPOT_SELL_DETAIL':
      return {
        ...state,
        spotSaleDetails: action.payload.data,
      }

    case 'SET_SPOT_PAYMENT':
      return {
        ...state,
        spotPayments: action.payload.data,
      }

    case 'SET_BINANCE_ORDERS':
      return {
        ...state,
        binanceOrders: action.payload.data,
      }

    case 'SET_TRADING_PROFIT':
      return {
        ...state,
        tradingProfit: action.payload.data,
      }

    case 'SET_TRADING_PROFIT_LOGS':
      return {
        ...state,
        tradingProfitLogs: action.payload.data,
      }

    case 'SET_REDUCE_PAYMENTS':
      return {
        ...state,
        reducePayments: action.payload.data,
      }

    case 'SET_REDUCE_CHILD_PAYMENTS':
      return {
        ...state,
        reduceChildPayments: action.payload.data,
      }

    case 'SET_LOADING':
      return { ...state, loading: action.payload }

    case 'SET_ACTION_LOADING':
      return { ...state, actionLoading: action.payload }

    default:
      throw new Error()
  }
}

const useSpotBuy = () => {
  const [state, dispatch] = React.useReducer(reducer, initialState)

  const fetchSpotBuys = async (query) => {
    dispatch({ type: 'SET_LOADING', payload: true })
    let data = await getAll(query)

    dispatch({ type: 'SET_SPOT_BUYS', payload: { data: data } })
    dispatch({ type: 'SET_LOADING', payload: false })
  }

  const fetchFutureOrders = async (query) => {
    dispatch({ type: 'SET_LOADING', payload: true })
    let data = await getAllFuture(query)

    dispatch({ type: 'SET_FUTURE_ORDERS', payload: { data: data } })
    dispatch({ type: 'SET_LOADING', payload: false })
  }

  const fetchSpotBuy = async (id) => {
    dispatch({ type: 'SET_LOADING', payload: true })
    let data = await getEach(id)

    dispatch({ type: 'SET_SPOT_BUY', payload: { data: data } })
    dispatch({ type: 'SET_LOADING', payload: false })
  }

  const fetchOrderDetail = async (id) => {
    dispatch({ type: 'SET_LOADING', payload: true })
    let data = await getDetail(id)

    dispatch({ type: 'SET_SPOT_BUY_DETAIL', payload: { data: data } })
    dispatch({ type: 'SET_LOADING', payload: false })
  }

  const fetchFutureOrderDetail = async (id) => {
    dispatch({ type: 'SET_LOADING', payload: true })
    let data = await getFutureDetail(id)

    dispatch({ type: 'SET_FUTURE_DETAIL', payload: { data: data } })
    dispatch({ type: 'SET_LOADING', payload: false })
  }

  

  const fetchSaleOrderDetail = async (id) => {
    dispatch({ type: 'SET_LOADING', payload: true })
    let data = await getSaleDetail(id)

    dispatch({ type: 'SET_SPOT_SELL_DETAIL', payload: { data: data } })
    dispatch({ type: 'SET_LOADING', payload: false })
  }

  const cancelOrder = async (values) => {
    dispatch({ type: 'SET_ACTION_LOADING', payload: true })
    await cancelOrders({ orders: values })
    dispatch({ type: 'SET_ACTION_LOADING', payload: false })
  }

  const deleteOrder = async (id) => {
    dispatch({ type: 'SET_ACTION_LOADING', payload: true })
    await deleteOrderService({ id: id })
    dispatch({ type: 'SET_ACTION_LOADING', payload: false })
  }

  const deleteFutureOrder = async (payload) => {
    dispatch({ type: 'SET_ACTION_LOADING', payload: true })
    await deleteFutureOrderService(payload)
    dispatch({ type: 'SET_ACTION_LOADING', payload: false })
  }

  const closePositionFutureOrder = async (payload) => {
    dispatch({ type: 'SET_ACTION_LOADING', payload: true })
    await closePositionFutureOrderService(payload)
    dispatch({ type: 'SET_ACTION_LOADING', payload: false })
  }

  

  

  const deletSaleOrder = async (id) => {
    dispatch({ type: 'SET_ACTION_LOADING', payload: true })
    await deletSaleOrderService({ id: id })
    dispatch({ type: 'SET_ACTION_LOADING', payload: false })
  }

  const fetchSpotPayment = async (id) => {
    dispatch({ type: 'SET_LOADING', payload: true })
    let data = await getSpotPayment(id)

    dispatch({ type: 'SET_SPOT_PAYMENT', payload: { data: data } })
    dispatch({ type: 'SET_LOADING', payload: false })
  }

  const fetchBinanceOrder = async (id) => {
    dispatch({ type: 'SET_LOADING', payload: true })
    let data = await getBinanceOrders(id)

    dispatch({ type: 'SET_BINANCE_ORDERS', payload: { data: data } })
    dispatch({ type: 'SET_LOADING', payload: false })
  }

  const savePayment = async (data) => {
    dispatch({ type: 'SET_ACTION_LOADING', payload: true })
    await savePaymentService({ data: data })
    dispatch({ type: 'SET_ACTION_LOADING', payload: false })
  }

  const giveLockPayment = async (body) => {
    dispatch({ type: 'SET_ACTION_LOADING', payload: true })
    await giveLockPaymentService(body)
    dispatch({ type: 'SET_ACTION_LOADING', payload: false })
  }

  const giveFutureLockPayment = async (body) => {
    dispatch({ type: 'SET_ACTION_LOADING', payload: true })
    await giveFutureLockPaymentService(body)
    dispatch({ type: 'SET_ACTION_LOADING', payload: false })
  }

  const fetchTradingProfit = async () => {
    dispatch({ type: 'SET_LOADING', payload: true })
    let data = await getTradingProfit()
    dispatch({ type: 'SET_TRADING_PROFIT', payload: { data: data } })
    dispatch({ type: 'SET_LOADING', payload: false })
  }

  const fetchTradingProfitLogs = async () => {
    dispatch({ type: 'SET_LOADING', payload: true })
    let data = await getTradingProfitLogs()
    dispatch({ type: 'SET_TRADING_PROFIT_LOGS', payload: { data: data } })
    dispatch({ type: 'SET_LOADING', payload: false })
  }

  const fetchReducePayments = async (id) => {
    dispatch({ type: 'SET_LOADING', payload: true })
    let data = await getReducePayments(id)
    dispatch({ type: 'SET_REDUCE_PAYMENTS', payload: { data: data } })
    dispatch({ type: 'SET_LOADING', payload: false })
  }

  const fetchReduceChildPayments = async (id) => {
    dispatch({ type: 'SET_LOADING', payload: true })
    let data = await getReduceChildPayments(id)
    dispatch({ type: 'SET_REDUCE_CHILD_PAYMENTS', payload: { data: data } })
    dispatch({ type: 'SET_LOADING', payload: false })
  }

  return {
    fetchReduceChildPayments,
    fetchReducePayments,
    fetchTradingProfit,
    fetchTradingProfitLogs,
    giveLockPayment,
    giveFutureLockPayment,
    deletSaleOrder,
    fetchSaleOrderDetail,
    fetchSpotBuys,
    fetchFutureOrders,
    fetchSpotBuy,
    fetchOrderDetail,
    closePositionFutureOrder,
    fetchFutureOrderDetail,
    fetchSpotPayment,
    savePayment,
    state: state,
    cancelOrder,
    deleteOrder,
    deleteFutureOrder,
    fetchBinanceOrder,
  }
}

export default useSpotBuy
