import useSpotBuy from 'hooks/useSpotBuy'
import React, { useCallback, useEffect, useMemo } from 'react'
import { useHistory, useParams } from 'react-router';
import Button from 'components/Button';
import List from './list';
import { NotificationManager } from 'react-notifications';
import useMember from 'hooks/useMember'
import useAdmin from 'hooks/useAdmin'

function SpotBuyDetail() {
    const { state: memberState, fetchLockWallets } = useMember();
    const headers = [
        'No',
        'Order ID',
        'Member',
        'Price',
        "Symbol",
        "Coin",
        "USDT",
        "Status",
    ]
    const { id } = useParams();
    const existedSale=useMemo(()=>{
        console.log(memberState.lockWallets);
         return memberState.lockWallets.find((lockWallet)=>lockWallet.saleOrderId && lockWallet.buyOrderId===parseInt(id))?true:false;
    },[id, memberState.lockWallets])

    

    const { fetchOrderDetail,cancelOrder,deleteOrder,fetchSpotBuy, state: spotBuyState } = useSpotBuy();
    const { currentAdmin } = useAdmin();
 
    const router=useHistory();

    useEffect(() => {
        async function loadData(){
            await fetchLockWallets()
            await fetchSpotBuy(id);
            await fetchOrderDetail(id)
        }
        loadData();
       
    }, [id])

    const handleCreateSaleOrder=useCallback(()=>{
        const checkUnFilled=spotBuyState.spotBuyDetails?.find((order)=>order?.binanceOrder?.status!=="FILLED");
        if (checkUnFilled){
            NotificationManager.error("You have orders to be filled");
            return; 
        }
        router.push(`/sale-order-create?coin=${spotBuyState.spotBuyDetails[0]?.spotBuy?.coin}`);
    },[router, spotBuyState.spotBuyDetails])

    const handleCancelOrder = useCallback(async () => {
        const orderIDs = [];
        spotBuyState.spotBuyDetails?.forEach((order) => {
            if (order?.binanceOrder?.status === "NEW")
                orderIDs.push({ orderId: order?.binanceOrder?.orderId, memberId: order.memberId,symbol: order?.binanceOrder?.symbol });
        })
        if (orderIDs.length===0){
            NotificationManager.error("You have no orders to cancel");
            return ;
        }
        await cancelOrder(orderIDs);
        await fetchOrderDetail(id);

    }, [cancelOrder, fetchOrderDetail, id, spotBuyState.spotBuyDetails]);

    const handleDeleteOrder=useCallback(async ()=>{
        const checkUnCacncel=spotBuyState.spotBuyDetails?.find((order)=>order?.binanceOrder?.status!=="CANCELED");
        if (checkUnCacncel){
            NotificationManager.error("You have orders to cancel");
            return; 
        }
        await deleteOrder(id);
        router.replace("/spot-order-list");

    },[deleteOrder, id, router, spotBuyState.spotBuyDetails])

    return (
        <>
            <List
                headers={headers}
                data={spotBuyState.spotBuyDetails}
                title="Order Detail"
                loading={spotBuyState.loading}
                buttons={<></>}
            />
            {!spotBuyState.loading  && !existedSale  && (<div className='d-flex justify-content-end'>
                <Button onClick={handleCreateSaleOrder} className="btn-success" disabled={spotBuyState.actionLoading}>Create Sale Ordre</Button>
                <Button onClick={handleCancelOrder} disabled={spotBuyState.actionLoading}>Cancel Orders</Button>
                <Button onClick={handleDeleteOrder} className="btn-danger" disabled={spotBuyState.actionLoading}>Delete</Button>
            </div>)}
        </>
    )
}

export default SpotBuyDetail