import React from 'react'
import Table from 'components/Table'
import Button from 'components/Button'
import { useHistory } from 'react-router'
import dayjs from 'dayjs'
function List({
  headers,
  data,
  title,
  buttons,
  loading,

}) {
  const router=useHistory();
  return (
    <Table
      title={title}
      headers={headers.map((e) => (
        <th scope="col" key={`thead-${Math.random()}`}>
          {e}
        </th>
      ))}
      loading={loading}
      buttons={buttons}
      rows={data?.map((e, index) => (
        <tr key={`table-row-${Math.random()}`}>
          <td>{index + 1}</td>
          <td>{dayjs(e.createdAt).format("DD/MM/YYYY")}</td>
          <td>{e.status}</td>
          <td>
          { e.status!=="Confirm" && <Button size="sm" onClick={()=>{
              router.push(`/wallet-locks-detail/${e.id}`);
            }}>Detail</Button>}
             {e.status==="Confirm" && <>
             <Button size="sm" onClick={()=>{
              router.push(`/reduce-payments/${e.id}`);
            }}>Payment Taken</Button>
             <Button size="sm" onClick={()=>{
              router.push(`/reduce-child-payments/${e.id}`);
            }}>Payment Given</Button>
             </>}
            
          </td>
        </tr>
      ))}
    />
  )
}

export default List
