import React from 'react'
import EntryGrid from 'components/Inputs/EntryGrid'
import { Col, FormGroup, Row, Form } from 'reactstrap'
import Button from 'components/Button'
import TextBox from 'components/Inputs/TextBox'
import { useForm } from 'react-hook-form'
import usePaymentMethod from 'hooks/usePaymentMethod'
import useAdmin from 'hooks/useAdmin'

function InputForm({ title, data }) {
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
    setValue,
  } = useForm()

  const { currentAdmin }=useAdmin();

  React.useEffect(() => {
    if (data) {
      setValue('email', data.email)
      setValue('address', data.address)
    }
  }, [data, setValue])

  const { storePaymentMethod, updatePaymentMethod } = usePaymentMethod()

  const submitData = async (values) => {
    const formData = new FormData()
    formData.append('email', values.email)
    formData.append('address', values.address)

    if (values.QRCode.length > 0) {
      formData.append('QRCode', values.QRCode[0])
    }
    data
      ? await updatePaymentMethod(data.id, formData)
      : await storePaymentMethod(formData)
    reset()
  }

  return (
    <EntryGrid title={title}>
      <Form onSubmit={handleSubmit(submitData)} encType="multipart/form-data">
        <div className="pt-2 pb-2">
          <Row>
            <Col md="4">
              <FormGroup>
                <label>Binance ID</label>
                <TextBox
                  className="form-control"
                  placeholder="Binance ID"
                  registerProps={register('email', {
                    required: 'Binance ID is required',
                  })}
                  errors={errors.email}
                />
              </FormGroup>
            </Col>
            <Col md="4">
              <FormGroup>
                <label>Address</label>
                <TextBox
                  className="form-control"
                  placeholder="Address"
                  type="text"
                  registerProps={register('address', {
                    required: 'Address is required',
                  })}
                  errors={errors.address}
                />
              </FormGroup>
            </Col>
            <Col md="4">
              <FormGroup>
                <label>QRCode</label>
                <TextBox
                  className="form-control"
                  placeholder="QRCode"
                  type="file"
                  registerProps={register('QRCode', {
                    required: data ? false : 'QRCode is required',
                  })}
                  errors={errors.QRCode}
                  accept="image/*"
                />
              </FormGroup>
            </Col>
            {data && (
              <Col md="4">
                <img src={data.QRCode} alt="QRCode" width={100} height={100} />
              </Col>
            )}
          </Row>
          {currentAdmin?.role==="admin" && <div className="mt-5 text-center">
            <Button>Save</Button>
            <Button
              className="ml-2"
              onClick={() => {
                reset()
              }}
            >
              Reset
            </Button>
          </div>}
        </div>
      </Form>
    </EntryGrid>
  )
}

export default InputForm
