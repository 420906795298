import React from 'react'
import Table from 'components/Table'


function List({
  headers,
  data,
  title,
  buttons,
  loading,
  total,
}) {
  return (
    <Table
      title={title}
      headers={headers.map((e) => (
        <th scope="col" key={`thead-${Math.random()}`}>
          {e}
        </th>
      ))}
      loading={loading}
      buttons={buttons}
      total={total}
      rows={data?.map((e, index) => (
        <tr key={e.id}>
          <td>{index + 1}</td>
          <td>
            {e.account.name} ({e.account.code})
          </td>
          <td>{e.balance} USDT</td>
        </tr>
      ))}
    />
  )
}

export default List
