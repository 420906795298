import useSpotBuy from 'hooks/useSpotBuy'
import React, { useCallback, useEffect,useMemo } from 'react'
import { useHistory, useParams } from 'react-router';
import Button from 'components/Button';
import List from './list';
import useMember from 'hooks/useMember'
import { NotificationManager } from 'react-notifications';

function SpotBuyDetail() {
    const { state: memberState, fetchLockWallets } = useMember();
    const headers = [
        'No',
        'Order ID',
        'Member',
        'Price',
        "Symbol",
        "Coin",
        "USDT",
        "Status",
    ]

    const { fetchOrderDetail,cancelOrder,deleteOrder,fetchSpotBuy,savePayment, state: spotBuyState } = useSpotBuy();

    const { id } = useParams();
    const router=useHistory();

    const existedPayment=useMemo(()=>{
        console.log(memberState.lockWallets);
         return memberState.lockWallets.find((lockWallet)=>lockWallet.status!=="PENDING" && lockWallet.saleOrderId===parseInt(id))?true:false;
    },[id, memberState.lockWallets])

    useEffect(() => {
        async function loadData(){
            await fetchSpotBuy(id);
            await fetchOrderDetail(id)
            fetchLockWallets();
        }
        loadData();
       
    }, [id])

    const handlePaymentSave=useCallback(async ()=>{
        const checkUnFilled=spotBuyState.spotBuyDetails?.find((order)=>order?.binanceOrder?.status!=="FILLED");
        if (checkUnFilled){
            NotificationManager.error("You have orders to be filled");
            return; 
        }
        const data=spotBuyState.spotBuyDetails.map((spotBuyDetail)=>{
            return {
                memberId:spotBuyDetail.memberId,
                balance:spotBuyDetail.quantity*spotBuyDetail.binanceOrder?.price
            }
        })
        await savePayment(data);
    },[spotBuyState.spotBuyDetails])

    const handleCancelOrder = useCallback(async () => {
        const orderIDs = [];
        spotBuyState.spotBuyDetails?.forEach((order) => {
            if (order?.binanceOrder?.status === "NEW")
                orderIDs.push({ orderId: order?.binanceOrder?.orderId, memberId: order.memberId,symbol: order?.binanceOrder?.symbol });
        })
        if (orderIDs.length===0){
            NotificationManager.error("You have no orders to cancel");
            return ;
        }
        await cancelOrder(orderIDs);
        await fetchOrderDetail(id);

    }, [cancelOrder, fetchOrderDetail, id, spotBuyState.spotBuyDetails]);

    const handleDeleteOrder=useCallback(async ()=>{
        const checkUnCacncel=spotBuyState.spotBuyDetails?.find((order)=>order?.binanceOrder?.status!=="CANCELED");
        if (checkUnCacncel){
            NotificationManager.error("You have orders to cancel");
            return; 
        }
        await deleteOrder(id);
        router.replace("/spot-order-list");

    },[deleteOrder, id, router, spotBuyState.spotBuyDetails])

    return (
        <>
            <List
                headers={headers}
                data={spotBuyState.spotBuyDetails}
                title="Order Detail"
                loading={spotBuyState.loading}
                buttons={<></>}
            />
            {!spotBuyState.loading  && !existedPayment && (<div className='d-flex justify-content-end'>
                <Button onClick={handlePaymentSave} disabled={spotBuyState.actionLoading} className="btn-success">Save Payment</Button>
                <Button onClick={handleCancelOrder} disabled={spotBuyState.actionLoading}>Cancel Orders</Button>
                <Button onClick={handleDeleteOrder} className="btn-danger" disabled={spotBuyState.actionLoading}>Delete</Button>
            </div>)}
        </>
    )
}

export default SpotBuyDetail