import React, { useEffect } from 'react'
import useSpotBuy from 'hooks/useSpotBuy';
import List from './list';
function FutureBuyList() {

  const {state:spotBuyState,fetchFutureOrders}=useSpotBuy();

  const headers = [
    'No',
    'Date',
    'Coin',
    'Price',
    "Percentage",
    "Leverate",
    "Margin",
    "Type",
    "Stop Price",
    "Status",
    "Actions"
]


  useEffect(()=>{
    fetchFutureOrders();
  },[])

  return (
    <List 
    headers={headers}
    data={spotBuyState.futureOrders}
    title="Future Order Lists"
    loading={spotBuyState.loading}
    buttons={<></>}
    />
  )
}

export default FutureBuyList