import React from 'react'
import Table from 'components/Table'
import dayjs from 'dayjs'

function List({ headers, data, title, buttons, loading, total }) {
  return (
    <Table
      title={title}
      headers={headers.map((e) => (
        <th scope="col" key={`thead-${Math.random()}`}>
          {e}
        </th>
      ))}
      loading={loading}
      buttons={buttons}
      total={total}
      rows={data?.map((e, index) => (
        <tr key={`table-row-${Math.random()}`}>
          <td>{index + 1}</td>
          <td>{e.member.name}</td>
          <td>{e.member.code}</td>
          <td>{e.amount}</td>
          <td>{dayjs(e.createdAt).format('DD/MM/YYYY')}</td>
        </tr>
      ))}
    />
  )
}

export default List
