import Button from 'components/Button'
import EntryGrid from 'components/Inputs/EntryGrid'
import queryString from 'query-string'
import useCoin from 'hooks/useCoin'
import useMember from 'hooks/useMember'
import React, { useEffect, useMemo, useState } from 'react'
import { Col,Row } from 'reactstrap'
import Select from 'react-select'
import { useHistory } from 'react-router'
import InputForm from './inputForm'
import { NotificationManager } from 'react-notifications'
const Binance = require('binance-api-node').default;
const client = Binance({
  apiKey: 'YOUR_API_KEY',
  apiSecret: 'YOUR_API_SECRET'
});



function FutureSaleCreate() {
    const { state: memberState, fetchFutureLockWallets } = useMember();
    const { fetchCoinList,state:coinState }=useCoin();
    const router=useHistory();
    const [selectedCoin,setSelectedCoin]=useState(null);
    const [currentTicker,setCurrentTicker]=useState(null);
    const history=useHistory();
  
    const query = queryString.parse(router.location.search)
    const listenToPriceUpdates = (symbol) => {
       
            client.ws.ticker(symbol, (ticker) => {
                setCurrentTicker(ticker);
              });
          
    };

    if (query.coin) listenToPriceUpdates(`${query.coin}USDT`);
     

    useEffect(()=>{
        async function loadData(){
            await fetchCoinList();
            await fetchFutureLockWallets()
        }
        loadData();
    },[])


    const coins=useMemo(()=>{
        if (coinState.coins){
            const existed=[];
             coinState.coins.forEach((coin)=>{
                if (!existed.find((exitedCoin)=>exitedCoin.baseCoin===coin.baseCoin)){
                   existed.push(coin);
                }
            })
            return existed;
        }
        return [];
    },[coinState.coins])

  return (
    <div>
        <EntryGrid title="Choose Symbol">
            <Row className='py-3'>
                <Col md="6">
                    <Select 
                        options={coins.map((coin)=>{
                            return {label:coin.baseCoin,value:coin.baseCoin}
                        })}
                        onChange={(value)=>{
                            setSelectedCoin(value);
                        }}
                    />
                </Col>
                <Col md="6">
                    <Button disabled={!selectedCoin} onClick={()=>{
                        if (memberState.futureLockWallets.length===0 || memberState.futureLockWallets.find((lockWallet)=>lockWallet.status!=="PENDING")){
                            NotificationManager.error("You should have a wallet that is locked. Cannot create new order")
                            return ;
                        }
                        window.location="/future-sale-order-create?coin="+selectedCoin.value;
                    }}>Submit</Button>
                </Col>
            </Row>
        </EntryGrid>
        {query.coin &&  <EntryGrid title={`${query.coin} : ${currentTicker?currentTicker?.bestBid:''}`}>
                    <InputForm onSubmit={(values)=>{
                        history.push(`future-sale-order-create-accounts?coin=${query.coin}&price=${values.price}&percentage=${values.percentage}&leverage=${values.leverage}&margin=${values.margin}&stopPrice=${values.stopPrice}&type=${values.limit}`);
                    }}/>
        </EntryGrid>}
    </div>
  )
}

export default FutureSaleCreate