import Home from 'pages/home'
import Login from 'pages/Login'
import NewAdmin from 'pages/admin/new'
import Admin from 'pages/admin/list'
import NewMember from 'pages/member/new'
import MemberDetail from 'pages/member/detail'
import MemberFundingWallet from 'pages/member/funding'
import Member from 'pages/member/list'
import NewPlan from 'pages/plan/new'
import Plan from 'pages/plan/list'
import PlanSetting from 'pages/plan/setting'
import PaymentMethod from 'pages/paymentMethod/list'
import NewPaymentMethod from 'pages/paymentMethod/new'
import Deposit from 'pages/deposit/list'
import WithdrawAllowance from 'pages/withdrawAllowance/list'
import NewWithdrawAllowance from 'pages/withdrawAllowance/new'
import Withdraw from 'pages/withdraw/list'
import BuyOrderCreate from 'pages/spot/buy/create'
import FutureBuyOrderCreate from 'pages/future/buy/create'
import SaleOrderCreate from 'pages/spot/sale/create'
import SpotOrderList from 'pages/spot/list'
import ReducePayment from 'pages/report/reducePayment';
import ReduceChildPayment from 'pages/report/reduceChildPayment';


import SpotSaleOrderList from 'pages/spot/sale/list'
import FutureOrderList from 'pages/future/order/list'
import FutureOrderDetail from 'pages/future/order/detail'


import WalletLocks from 'pages/wallets/locks';
import WalletLocksDetail from 'pages/wallets/locks/detail';
import PackageProfit from 'pages/report/package';
import TradingProfit from 'pages/report/trading';




import Purchase from 'pages/purchase/list'
import Transfer from 'pages/transfer/list'
import BinanceOrderList from 'pages/spot/binance/order';
import SpotBuyAccountList from 'pages/spot/buy/accounts'
import FutureBuyAccountList from 'pages/future/buy/accounts'
import FutureLockWallet from 'pages/future/locks'
import FutureLockWalletDetail from 'pages/future/locks/detail'

import CreateFutureLockWallet from 'pages/future/locks/create'

import SpotSaleAccountList from 'pages/spot/sale/accounts'

import SpotOrderDetail from 'pages/spot/buy/detail'
import SpotOrderDetailSale from 'pages/spot/sale/detail'
import SpotPayment from 'pages/spot/payment'

import FutureSaleOrderCreate from 'pages/future/sale/create'
import FutureSaleAccountList from 'pages/future/sale/accounts'


import Wallet from 'pages/wallet/list'
import History from 'pages/wallet/history'
import NewTransferAllowance from 'pages/transferAllowance/new'
import TransferAllowance from 'pages/transferAllowance/list'
import WalletReport from 'pages/wallet/walletReport'
import Blog from 'pages/blog/list'



const routes = [
  {
    path: '/login',
    name: 'login',
    icon: 'fas fa-home text-primary',
    component: Login,
    layout: '/auth',
    invisible: true,
  },
  {
    path: '',
    name: 'Home',
    icon: 'fas fa-home text-cyan',
    component: Home,
    layout: '/',
  },
  {
    collapse: true,
    name: 'Spot Trading',
    icon: 'ni ni-single-02 text-danger',
    state: 'spotCollapse',
    views: [
      {
        path: 'buy-order-create',
        name: 'Buy Order - Create',
        miniName: 'OQ',
        component: BuyOrderCreate,
        layout: '/',
      },
      {
        path: 'spot-order-list',
        name: 'Buy Order - List',
        miniName: 'OQ',
        component: SpotOrderList,
        layout: '/',
      },
      {
        path: 'sale-order-create',
        name: 'Sale Order - Create',
        miniName: 'OQ',
        component: SaleOrderCreate,
        layout: '/',
        invisible:true,
      },
      {
        path: 'spot-sale-order-list',
        name: 'Sale Order - List',
        miniName: 'OQ',
        component: SpotSaleOrderList,
        layout: '/',
      },
     
      {
        path: 'spot-order-detail/:id',
        name: 'Order - Detail',
        miniName: 'OQ',
        component: SpotOrderDetail,
        layout: '/',
        invisible:true
      },
      {
        path: 'spot-order-detail-sale/:id',
        name: 'Order - Detail',
        miniName: 'OQ',
        component: SpotOrderDetailSale,
        layout: '/',
        invisible:true
      },
      {
        path: 'buy-order-create-accounts',
        name: 'Buy Order - Accounts',
        miniName: 'OQ',
        component: SpotBuyAccountList,
        layout: '/',
        invisible:true
      },
     
     
      {
        path: 'binance-order-list',
        name: 'Binance Order - Setting',
        miniName: 'OQ',
        component: BinanceOrderList,
        adminOnly:true,
        layout: '/',
      },
      {
        path: 'sale-order-create-accounts',
        name: 'Sale Order - Accounts',
        miniName: 'OQ',
        component: SpotSaleAccountList,
        layout: '/',
        invisible:true
      },
      {
        path: 'spot-payment/:id',
        name: 'Sale Order - Payment',
        miniName: 'OQ',
        component: SpotPayment,
        layout: '/',
        invisible:true
      },
      {
        path: 'spot-wallets',
        name: 'Wallets - Locks',
        miniName: 'OQ',
        component: WalletLocks,
        layout: '/',
       
      },
      {
        path: 'wallet-locks-detail/:id',
        name: 'Wallets Locks - Detail',
        miniName: 'OQ',
        component: WalletLocksDetail,
        layout: '/',
        invisible:true,
      },
     
      
    ],
  },
  {
    collapse: true,
    name: 'Future Trading',
    icon: 'ni ni-single-02 text-danger',
    state: 'futureCollapse',
    views: [
      {
        path: 'future-buy-order-create',
        name: 'Order - Create',
        miniName: 'OQ',
        component: FutureBuyOrderCreate,
        layout: '/',
      },   
      // {
      //   path: 'future-sale-order-create',
      //   name: 'Sale Order - Create',
      //   miniName: 'OQ',
      //   component: FutureSaleOrderCreate,
      //   layout: '/',
      // },   
      {
        path: 'future-buy-order-create-accounts',
        name: 'Future Buy Order - Accounts',
        miniName: 'OQ',
        component: FutureBuyAccountList,
        layout: '/',
        invisible:true
      },  
      {
        path: 'future-sale-order-create-accounts',
        name: 'Future Sale Order - Accounts',
        miniName: 'OQ',
        component: FutureSaleAccountList,
        layout: '/',
        invisible:true
      },      
      {
        path: 'future-lock-wallets',
        name: 'Future Lock Wallets - List',
        miniName: 'OQ',
        component: FutureLockWallet,
        layout: '/',
      },   
      {
        path: 'future-lock-wallets-detail/:id',
        name: 'Future Lock Wallets Detail - List',
        miniName: 'OQ',
        component: FutureLockWalletDetail,
        layout: '/',
        invisible:true,
      },   
      {
        path: 'create-future-lock-wallets',
        name: 'Future Lock Wallets - Create',
        miniName: 'OQ',
        component: CreateFutureLockWallet,
        layout: '/',
        invisible:true
      },   
      {
        path: 'future-order-list',
        name: 'Future Order - List',
        miniName: 'OQ',
        component: FutureOrderList,
        layout: '/',
      },
      {
        path: 'future-order-detail/:id',
        name: 'Future Order - Detail',
        miniName: 'OQ',
        component: FutureOrderDetail,
        layout: '/',
        invisible:true
      },
      
    ],
  },
  {
    collapse: true,
    name: 'Admin',
    icon: 'ni ni-single-02 text-danger',
    state: 'adminCollapse',
    views: [
      {
        path: 'admin-create',
        name: 'Admin - Create',
        miniName: 'OQ',
        component: NewAdmin,
        layout: '/',
      },
      {
        path: 'admin-query',
        name: 'Admin - List',
        miniName: 'OQ',
        component: Admin,
        layout: '/',
      },
    ],
  },
  {
    collapse: true,
    name: 'Members',
    icon: '	ni ni-circle-08 text-yellow',
    state: 'memberCollapse',
    views: [
      {
        path: 'member-create',
        name: 'Members - Create',
        miniName: 'OQ',
        component: NewMember,
        layout: '/',
      },
      {
        path: 'member-query',
        name: 'Members - List',
        miniName: 'OQ',
        component: Member,
        layout: '/',
      },
      {
        path: 'member-detail/:id',
        name: 'Members - Detail',
        miniName: 'OQ',
        component: MemberDetail,
        layout: '/',
        invisible: true,
      },
      {
        path: 'member-funding-wallet',
        name: 'Members - Funding Wallet',
        miniName: 'OQ',
        component: MemberFundingWallet,
        layout: '/',
      },
    ],
  },
  {
    collapse: true,
    name: 'Plan',
    icon: '	ni ni-align-left-2 text-warning',
    state: 'planCollapse',
    views: [
      {
        path: 'plan-create',
        name: 'Plan - Create',
        miniName: 'OQ',
        component: NewPlan,
        layout: '/',
      },
      {
        path: 'plan-query',
        name: 'Plan - List',
        miniName: 'OQ',
        component: Plan,
        layout: '/',
      },
      {
        path: 'plan-setting/:id',
        name: 'Plan - setting',
        miniName: 'OQ',
        component: PlanSetting,
        layout: '/',
        invisible: true,
      },
    ],
  },
  {
    collapse: true,
    name: 'Payment Method',
    icon: '	ni ni-money-coins text-danger',
    state: 'paymentMethodCollapse',
    views: [
      {
        path: 'Payment-method-create',
        name: 'Payment Method - Create',
        miniName: 'OQ',
        component: NewPaymentMethod,
        layout: '/',
      },
      {
        path: 'payment-method-query',
        name: 'Payment Method - List',
        miniName: 'OQ',
        component: PaymentMethod,
        layout: '/',
      },
    ],
  },
  {
    path: 'deposit-query',
    name: 'Deposits',
    icon: 'ni ni-credit-card text-green',
    component: Deposit,
    layout: '/',
  },
  {
    path: 'withdraw-query',
    name: 'Withdraws',
    icon: 'ni ni-fat-delete text-danger ',
    component: Withdraw,
    layout: '/',
  },
  {
    path: 'purchase-query',
    name: 'Purchases',
    icon: 'ni ni-fat-add text-warning',
    component: Purchase,
    layout: '/',
  },
  // {
  //   path: 'active-plan-query',
  //   name: 'Active Plan',
  //   icon: 'ni ni-fat-add text-warning',
  //   component: ActivePlans,
  //   layout: '/',
  // },
  {
    path: 'transfer-query',
    name: 'Transfers',
    icon: 'ni ni-curved-next text-green',
    component: Transfer,
    layout: '/',
  },
  {
    path: 'wallet-query',
    name: 'Wallets',
    icon: 'fa fa-file-word text-green',
    component: Wallet,
    layout: '/',
  },
 
   {
    path: 'history-query/:id',
    name: 'Wallets',
    icon: 'fa fa-file-word text-green',
    component: History,
    layout: '/',
    invisible: true,
  },
  {
    path: 'blog-query',
    name: 'Blogs',
    icon: 'fa fa-file-word text-green',
    component: Blog,
    layout: '/',
  },
  // {
  //   path: 'blog-create',
  //   name: 'BlogCreate',
  //   icon: 'fa fa-file-word text-green',
  //   component: NewBlog,
  //   layout: '/',
  //   invisible: true,
  // },
  {
    collapse: true,
    name: 'Setting',
    icon: '	ni ni-settings-gear-65 text-black',
    state: 'settingCollapse',
    views: [
      {
        path: 'Withdraw-allowance-create',
        name: 'Withdraw Allowance - Create',
        miniName: 'OQ',
        component: NewWithdrawAllowance,
        layout: '/',
        invisible: true,
      },
      {
        path: 'withdraw-allowance-query',
        name: 'Withdraw Allowance - List',
        miniName: 'OQ',
        component: WithdrawAllowance,
        layout: '/',
      },
      {
        path: 'transfer-allowance-create',
        name: 'Transfer Allowance - Create',
        miniName: 'OQ',
        component: NewTransferAllowance,
        layout: '/',
        invisible: true,
      },
      {
        path: 'transfer-allowance-query',
        name: 'Transfer Allowance - List',
        miniName: 'OQ',
        component: TransferAllowance,
        layout: '/',
      },
      // {
      //   path: 'token-price',
      //   name: 'Token Price',
      //   miniName: 'TP',
      //   component: TokenPrice,
      //   layout: '/',
      // },
    ],
  },
  {
    collapse: true,
    name: 'Report',
    icon: '	fa fa-file-word text-green',
    state: 'logCollapse',
    views: [
      {
        path: 'wallet-report',
        name: 'Wallet - Report',
        miniName: 'AFL',
        component: WalletReport,
        layout: '/',
      },
      {
        path: 'package-profile',
        name: 'Package - Report',
        miniName: 'PLR',
        component: PackageProfit,
        layout: '/',
      },
      {
        path: 'trading-profit',
        name: 'Trading - Report',
        miniName: 'PLR',
        component: TradingProfit,
        layout: '/',
      },
    ],
  },
  {
    path: 'reduce-payments/:id',
    name: 'Reduce Payment',
    icon: 'fas fa-home text-cyan',
    component: ReducePayment,
    layout: '/',
    invisible:true,
  },
  {
    path: 'reduce-child-payments/:id',
    name: 'Reduce Child Payment',
    icon: 'fas fa-home text-cyan',
    component: ReduceChildPayment,
    layout: '/',
    invisible:true,
  },
]

const getAllRoutes = () => {
  const allRoutes = []
  routes.forEach((e) => {
    if (e.collapse) {
      e.views.map((v) => allRoutes.push(v))
    } else allRoutes.push(e)
  })
  return allRoutes
}
const allRoutes = getAllRoutes()

export { routes, allRoutes }
