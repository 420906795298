import Button from 'components/Button';
import useSpotBuy from 'hooks/useSpotBuy'
import React, { useCallback, useEffect } from 'react'
import { useParams } from 'react-router';
import List from './list';
function SpotPayment() {
    const { id } = useParams();
    const { fetchSpotPayment,saveSpotPayment, state: spotBuyState } = useSpotBuy();

    const headers = [
        'No',
        'Member',
        'Old Balance',
        'New Balance',
        "Difference",
        "Status",
    ]

    useEffect(() => {
        async function loadData() {
            fetchSpotPayment(id);
        }
        loadData();
    }, [id]);

    const handleSave=useCallback(async ()=>{
        await saveSpotPayment(id);
        window.location="/spot-order-list";
    },[]);

    return (
        <>
            <List
                headers={headers}
                data={spotBuyState.spotPayments}
                title="Spot Payment Lists"
                loading={spotBuyState.loading}
                buttons={<></>}
            />
            <div className='d-flex jusity-content-end'>
                <Button className="btn btn-success" onClick={handleSave}>Save</Button>
            </div>
        </>
    )
}

export default SpotPayment