import React from 'react'
import usePurchase from 'hooks/usePurchase'
import Button from 'components/Button'
import List from './list'

function ReportPackageProfit() {

  const { state, fetchPurchaseProfit,fetchPurchaseProfitLogs } = usePurchase()

  const headers = ['No', 'Amount','Created At']

  async function loadData(){
    await fetchPurchaseProfit()
    await fetchPurchaseProfitLogs();
  }


  React.useEffect(() => {
    loadData();
  }, [])

  return (
    <div>
      <div className='card p-3'>
        <h3>Total Profit : {state.purchase_profit.amount}</h3>
      </div>
      <List
        headers={headers}
        total={state.total}
        data={state.purchase_profit_logs}
        title="Purchase Profit Logs"
        loading={state.loading}
        onSelect={(e) => {}}
        buttons={
          <>
            <Button
              onClick={() => {
                loadData();
              }}
            >
              Refresh
            </Button>
          </>
        }
      />
      
    </div>
  )
}

export default ReportPackageProfit