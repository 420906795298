import React, { useEffect } from 'react'
import List from './list'
import Button from 'components/Button'
import useMember from 'hooks/useMember';

function CreateFutureLockWallet() {

  const { fetchFutureWallets,lockFutureWallet,transferFromFunding, state: memberState  } = useMember();

  const headers = [
    'No',
    'Member',
    'Future Wallet',
]

  useEffect(() => {
    async function loadData() {
        await fetchFutureWallets();
    }
    loadData();
}, [])


  return (
    <List
      headers={headers}
      data={memberState.futureWallets}
      title="CreateFuture Wallet Locks"
      loading={false}
      onSelect={(e) => {}}
      buttons={
        <>
          {memberState.futureWallets.length>0 && <Button
            onClick={async () => {
              await lockFutureWallet();
            }}
          >
            Lock Wallet
          </Button>}
          <Button onClick={async ()=>{
            await transferFromFunding("FUNDING_UMFUTURE");
          }}>Transfer from Funding</Button>
        </>
      }
    />
  )
}

export default CreateFutureLockWallet
