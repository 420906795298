import useSpotBuy from 'hooks/useSpotBuy'
import React, { useCallback, useEffect, useMemo } from 'react'
import { useHistory, useParams } from 'react-router'
import Button from 'components/Button'
import List from './list'
import { NotificationManager } from 'react-notifications'

function FutureOrderDetail() {
  const headers = [
    'No',
    'Order ID',
    'Member',
    'Price',
    'Symbol',
    'Coin',
    'USDT',
    'Status',
  ]

  const {
    fetchFutureOrderDetail,
    deleteFutureOrder,
    closePositionFutureOrder,
    state: spotBuyState,
  } = useSpotBuy()

  const { id } = useParams()
  const router = useHistory()

  useEffect(() => {
    async function loadData() {
      await fetchFutureOrderDetail(id)
    }
    loadData()
  }, [id])

  

  const handleCloseFutureOrder = useCallback(async () => {
    const checkUnCacncel = spotBuyState.futureOrderDetails?.find(
      (order) => order?.binanceOrder?.status !== 'FILLED'
    )
    if (checkUnCacncel) {
      NotificationManager.error('You have not filled orders. So, you cannot stop')
      return
    }

    await closePositionFutureOrder({
      id: id,
      orders: spotBuyState.futureOrderDetails.map((d) => {
        return {
          orderId: d.binanceOrder?.orderId,
          symbol: d.binanceOrder?.symbol,
          memberId:d.member?.id
        }
      }),
    })
    router.replace('/future-order-list')
  }, [spotBuyState.futureOrderDetails, closePositionFutureOrder, id, router])


  const handleDeleteFutureOrder = useCallback(async () => {
    const checkUnCacncel = spotBuyState.futureOrderDetails?.find(
      (order) => order?.binanceOrder?.status === 'FILLED'
    )
    if (checkUnCacncel) {
      NotificationManager.error('You have orders running.Cannot Delete')
      return
    }

    const checkUnMarket = spotBuyState.futureOrderDetails?.find(
        (order) => order?.binanceOrder?.type === 'MARKET'
      )

    if (checkUnMarket){
        NotificationManager.error('You have market orders running.Cannot Delete')
        return
    }

    await deleteFutureOrder({
      id: id,
      orders: spotBuyState.futureOrderDetails.map((d) => {
        return {
          orderId: d.binanceOrder?.orderId,
          symbol: d.binanceOrder?.symbol,
          memberId:d.member?.id
        }
      }),
    })
    router.replace('/future-order-list')
  }, [id, deleteFutureOrder, router, spotBuyState.futureOrderDetails])

  return (
    <>
      <List
        headers={headers}
        data={spotBuyState.futureOrderDetails}
        title="Order Detail"
        loading={spotBuyState.loading}
        buttons={<></>}
      />
      {!spotBuyState.loading && (
        <div className="d-flex justify-content-end">
          <Button
            onClick={handleDeleteFutureOrder}
            className="btn-danger"
            disabled={spotBuyState.actionLoading}
          >
            Delete
          </Button>
          <Button
            onClick={handleCloseFutureOrder}
            className="btn-danger"
            disabled={spotBuyState.actionLoading}
          >
            Close Position
          </Button>
        </div>
      )}
    </>
  )
}

export default FutureOrderDetail
