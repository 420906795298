import React from 'react'
import Table from 'components/Table'

function List({
  headers,
  data,
  title,
  buttons,
  loading,

}) {
  return (
    <Table
      title={title}
      headers={headers.map((e) => (
        <th scope="col" key={`thead-${Math.random()}`}>
          {e}
        </th>
      ))}
      loading={loading}
      buttons={buttons}
      rows={data?.map((e, index) => (
        <tr key={`table-row-${Math.random()}`}>
          <td>{index + 1}</td>
          <td>{e.binanceOrder?.orderId}</td>
          <td>{e.member?.name} ({e.member?.code})</td>
          <td>{Number(e.binanceOrder?.price).toFixed(3)}</td>
          <td>{e.binanceOrder?.symbol}</td>
          <td>{e.quantity.toFixed(4)} {e.spotBuy?.coin}</td>
          <td>{e.quantity*e.binanceOrder?.price}</td>
          <td>{e.binanceOrder?.status}</td>
        </tr>
      ))}
    />
  )
}

export default List
