import Button from 'components/Button'
import useMember from 'hooks/useMember'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import List from './list'
import { useParams } from 'react-router'
import useAdmin from 'hooks/useAdmin'
import TextBox from 'components/Inputs/TextBox'
import { Col, Row } from 'reactstrap'
import useSpotBuy from 'hooks/useSpotBuy'
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min'
import queryString from 'query-string'

function WalletLockDetail() {
  const {
    state: memberState,
    fetchFutureLockWalletDetail,
    fetchFutureLockWallets,
  } = useMember()

  const { state: spotBuyState, giveFutureLockPayment } = useSpotBuy()
  

  const { id } = useParams()

  const existedPayment = useMemo(() => {
    return memberState.futureLockWallets.find(
      (lockWallet) =>
        lockWallet.id === parseInt(id) && lockWallet.status === 'PENDING'
    )
      ? true
      : false
  }, [id, memberState.futureLockWallets])

  const { currentAdmin } = useAdmin()

  const [search,setSearch]=useState("");

  const headers = [
    'No',
    'Member',
    'Initial Balance',
    'Close Balance',
    'Diff',
    'Percentage',
  ]

  const history=useHistory();

  const query = queryString.parse(history.location.search)

  const handleConfirmPayment = useCallback(async () => {
    await giveFutureLockPayment({id:id,percentage:query.percentage})
    history.replace("/future-lock-wallets?limit=10&page=1")
  }, [giveFutureLockPayment, history, id, query.percentage])

  useEffect(() => {
    async function loadData() {
      await fetchFutureLockWallets()
      await fetchFutureLockWalletDetail(id)
    }
    loadData()
  }, [])

  return (
    <div>
      <div className="d-flex mb-3">
        <Row>
          <Col md="10">
            <TextBox placeholder="Percentage"  onChange={(e)=>setSearch(e.target.value)} type="number" />
          </Col>
          <Col md="1">
            <Button disabled={search.length===0} onClick={()=>{
                history.push(`/future-lock-wallets-detail/${id}?limit=10&page=1&percentage=`+search)
            }}>Submit</Button>
          </Col>
        </Row>
      </div>
      <List
        percentage={query?.percentage}
        headers={headers}
        data={memberState.futureLockWalletDetails}
        title="Lock Wallet Detail"
        loading={memberState.loading}
        buttons={<></>}
      />
      <div className="d-flex justify-content-end">
        {!memberState.loading &&
          existedPayment &&
          currentAdmin?.role === 'admin' && (
            <Button
              onClick={handleConfirmPayment}
              className="btn-success"
              disabled={!query.percentage || spotBuyState.actionLoading}
            >
              Confirm
            </Button>
          )}
      </div>
    </div>
  )
}

export default WalletLockDetail
