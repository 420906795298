import React from 'react'
import { getAll, getAllActive, getPurchaseProfit, getPurchaseProfitLogs } from 'services/purchaseService'

const initialState = {
  purchases: [],
  active_purchases: [],
  purchase_profit: {},
  purchase_profit_logs: [],
  loading: false,
  total: 0,
}

function reducer(state, action) {
  switch (action.type) {
    case 'SET_PURCHASE':
      return {
        ...state,
        purchases: action.payload.data.purchases,
        total: action.payload.data.total,
      }
    case 'SET_ACTIVE_PURCHASE':
      return {
        ...state,
        active_purchases: action.payload.data.purchases,
        total: action.payload.data.total,
      }

    case 'SET_PURCHASE_PROFIT':
      return {
        ...state,
        purchase_profit: action.payload.data,
        total: action.payload.data.total,
      }

    case 'SET_PURCHASE_PROFIT_LOGS':
      return {
        ...state,
        purchase_profit_logs: action.payload.data,
        total: action.payload.data.total,
      }



    case 'SET_LOADING':
      return { ...state, loading: action.payload }
    default:
      throw new Error()
  }
}

const usePurchase = () => {
  const [state, dispatch] = React.useReducer(reducer, initialState)

  const fetchPurchase = async (query) => {
    dispatch({ type: 'SET_LOADING', payload: true })
    let data = await getAll(query)
    dispatch({ type: 'SET_PURCHASE', payload: { data: data } })
    dispatch({ type: 'SET_LOADING', payload: false })
  }

  const fetchActivePlan = async (query) => {
    dispatch({ type: 'SET_LOADING', payload: true })
    let data = await getAllActive(query)
    dispatch({ type: 'SET_ACTIVE_PURCHASE', payload: { data: data } })
    dispatch({ type: 'SET_LOADING', payload: false })
  }

  const fetchPurchaseProfit = async () => {
    dispatch({ type: 'SET_LOADING', payload: true })
    let data = await getPurchaseProfit()
    dispatch({ type: 'SET_PURCHASE_PROFIT', payload: { data: data } })
    dispatch({ type: 'SET_LOADING', payload: false })
  }


  const fetchPurchaseProfitLogs = async () => {
    dispatch({ type: 'SET_LOADING', payload: true })
    let data = await getPurchaseProfitLogs()
    dispatch({ type: 'SET_PURCHASE_PROFIT_LOGS', payload: { data: data } })
    dispatch({ type: 'SET_LOADING', payload: false })
  }



  return {
    fetchPurchase,
    fetchActivePlan,
    fetchPurchaseProfit,
    fetchPurchaseProfitLogs,
    state: state,
  }
}

export default usePurchase
