import useSpotBuy from 'hooks/useSpotBuy';
import React, { useEffect } from 'react'
import { useParams } from 'react-router'
import List from './list';

function ReduceChildPayment() {
    const {id}=useParams();

    const headers = ['No', 'Member Name','Member Code','Amount','Created At']

    const {state,fetchReduceChildPayments}=useSpotBuy();

    useEffect(()=>{
        fetchReduceChildPayments(id);
    },[])

  return (
    <List
        headers={headers}
        data={state.reduceChildPayments}
        title="Payment Given"
        loading={state.loading}
       
      />
  )
}

export default ReduceChildPayment