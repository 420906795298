import React from 'react'
import {
  getAll,
} from 'services/binanceService'

const initialState = { coins: [], loading: false }

function reducer(state, action) {
  switch (action.type) {
    case 'SET_COIN_LIST':
      return { ...state, coins: action.payload.data }
    case 'SET_LOADING':
      return { ...state, loading: action.payload }
    default:
      throw new Error()
  }
}

const useCoin = () => {
  const [state, dispatch] = React.useReducer(reducer, initialState)

  const fetchCoinList = async () => {
    dispatch({ type: 'SET_LOADING', payload: true })
    let data = await getAll()
    dispatch({ type: 'SET_COIN_LIST', payload: { data: data } })
    dispatch({ type: 'SET_LOADING', payload: false })
  }



  return {
   fetchCoinList,
    state: state,
  }
}

export default useCoin
