import React, { useEffect } from 'react'
import queryString from 'query-string'
import { useHistory } from 'react-router';
import useMember from 'hooks/useMember';
import List from './list';
import { Button } from 'reactstrap';
import { NotificationManager } from 'react-notifications';

function SpotBuyAccountList() {
    const router = useHistory();
    const query = queryString.parse(router.location.search)

    const headers = [
        'No',
        'Member',
        'Spot Wallet',
        'Useable Coin',
        "Received Amount",
        'Status',
    ]

    const { fetchSpotWallet,openSellSpotOrders, state: memberState } = useMember();

    const { coin, price, percentage ,id} = query;


    useEffect(() => {
        async function loadData() {
            await fetchSpotWallet(coin);
        }
        loadData();
    }, [coin])

    const handleSubmitRequest=async ()=>{
      
        const memberIDs=[];
        
        memberState.spotWallets.forEach((wallet)=>{
            const usabledAmount=(wallet.balance/100)*percentage;
            if (usabledAmount*price>=10)
                memberIDs.push(wallet.account.id);
        })

        if (memberIDs.length===0) {
            NotificationManager.error("You have no orders to open")
            return ;
        }

        const body={
            symbol:`${coin}USDT`,
            side:"SELL",
            percentage:percentage,
            price:price,
            coin:coin,
            memberIDs:memberIDs,
            id:id
        }

        await openSellSpotOrders(body);
    }

    return (
        <div>
            <List
                headers={headers}
                data={memberState.spotWallets}
                title="User Wallet Lists"
                loading={memberState.loading}
                buttons={<></>}
                query={query}
            />
            <div className='d-flex justify-content-end'>
                <Button disabled={memberState.actionLoading || memberState.spotWallets.length===0} onClick={handleSubmitRequest}>Save</Button>
            </div>
        </div>
    )
}

export default SpotBuyAccountList