import React from 'react'
import { Col, Row, Form } from 'reactstrap'
import TextBox from 'components/Inputs/TextBox'
import Button from 'components/Button'
import { useForm } from 'react-hook-form'
import SelectBox from 'components/SelectBox'

function InputForm({ onSubmit }) {
  const {
    register,
    control,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm({
    defaultValues: {
      margin: false,
      limit: 'LIMIT',
      side: 'BUY',
      positionSide:"LONG"
    },
  })

  const submitData = async (values) => {
    onSubmit(values)
  }

  return (
    <Form onSubmit={handleSubmit(submitData)}>
      <div className="py-3">
        <div className="py-2">
          <h3>Open Future Limit Buy Order</h3>
        </div>
        <Row>
          <Col md="6">
            <label>Side</label>
            <SelectBox
              control={control}
              className="form-control"
              name="side"
              label="side"
              options={[
                { label: 'BUY', value: 'BUY' },
                { label: 'SELL', value: 'SELL' },
              ]}
            />
          </Col>
          <Col md="6">
            <label>Position Side</label>
            <SelectBox
              control={control}
              className="form-control"
              name="positionSide"
              label="positionSide"
              options={[
                { label: 'LONG', value: 'LONG' },
                { label: 'SHORT', value: 'SHORT' },
              ]}
            />
          </Col>
          <Col md="6">
            <label>Limit</label>
            <SelectBox
              control={control}
              className="form-control"
              name="limit"
              label="limit"
              options={[
                { label: 'LIMIT', value: 'LIMIT' },
                { label: 'MARKET', value: 'MARKET' },
                { label: 'TAKE_PROFIT', value: 'TAKE_PROFIT' },
                { label: 'STOP', value: 'STOP' },
              ]}
            />
          </Col>
          {watch('limit') !== 'MARKET' && (
            <Col md="6">
              <label>Price</label>
              <TextBox
                className="form-control"
                placeholder="Price"
                type="text"
                registerProps={register('price', {
                  required: 'Price is required',
                })}
                errors={errors.price}
              />
            </Col>
          )}
          <Col md="6">
            <label>Percentage</label>
            <TextBox
              className="form-control"
              placeholder="percentage"
              type="text"
              registerProps={register('percentage', {
                required: 'Percentage is required',
              })}
              errors={errors.percentage}
            />
          </Col>
          <Col md="6">
            <label>Margin</label>
            <SelectBox
              control={control}
              className="form-control"
              name="margin"
              label="margin
                            "
              options={[
                { label: 'Cross', value: false },
                { label: 'Isoloated', value: true },
              ]}
            />
          </Col>
          <Col md="6">
            <label>Leverage</label>
            <TextBox
              className="form-control"
              placeholder="Leverage"
              type="text"
              registerProps={register('leverage', {
                required: 'Leverage is required',
              })}
              errors={errors.leverage}
            />
          </Col>

          {watch('limit') !== 'LIMIT' && (
            <Col md="6">
              <label>Stop Price</label>
              <TextBox
                className="form-control"
                placeholder="Stop Price"
                type="text"
                registerProps={register('stopPrice')}
                errors={errors.stopPrice}
              />
            </Col>
          )}
        </Row>
        <Row
          style={{ display: 'flex', justifyContent: 'center' }}
          className="py-3"
        >
          <Button type="submit">Submit</Button>
        </Row>
      </div>
    </Form>
  )
}

export default InputForm
