import useMember from 'hooks/useMember'
import React, { useEffect } from 'react'
import Button from 'components/Button'
import List from './list'

function WalletLocks() {
  const { state: memberState, fetchFundingWallet,transferToFunding } = useMember()

  const headers = ['No', 'Member', 'Wallet']

  useEffect(() => {
    async function loadData() {
      await fetchFundingWallet()
    }
    loadData()
  }, [])

  return (
    <div>
      <List
        headers={headers}
        data={memberState.fundingWallets}
        title="Funding Wallets"
        loading={false}
        onSelect={(e) => {}}
        buttons={
          <>
            <Button
              onClick={async () => {
                await transferToFunding("UMFUTURE_FUNDING");
              }}
            >
              Transfer from Future
            </Button>
            <Button
              onClick={async () => {
                await transferToFunding("Main_FUNDING");
              }}
            >
              Transfer from Spot
            </Button>
          </>
        }
      />
    </div>
  )
}

export default WalletLocks
