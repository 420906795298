import Button from 'components/Button'
import EntryGrid from 'components/Inputs/EntryGrid'
import queryString from 'query-string'
import useCoin from 'hooks/useCoin'
import React, { useEffect, useMemo, useState } from 'react'
import { Col,Row } from 'reactstrap'
import Select from 'react-select'
import { useHistory } from 'react-router'
import InputForm from './inputForm'
const Binance = require('binance-api-node').default;
const client = Binance({
  apiKey: 'YOUR_API_KEY',
  apiSecret: 'YOUR_API_SECRET'
});



function SpotBuyCreate() {

    const { fetchCoinList,state:coinState }=useCoin();
    const router=useHistory();
    const [selectedCoin,setSelectedCoin]=useState(null);
    const [currentTicker,setCurrentTicker]=useState(null);
    const history=useHistory();
  
    const query = queryString.parse(router.location.search)
    const listenToPriceUpdates = (symbol) => {
       
            client.ws.ticker(symbol, (ticker) => {
                setCurrentTicker(ticker);
              });
          
    };

    if (query.coin) listenToPriceUpdates(`${query.coin}USDT`);
     

    useEffect(()=>{
        async function loadData(){
            await fetchCoinList();
        }
        loadData();
    },[])


    const coins=useMemo(()=>{
        if (coinState.coins){
            const existed=[];
             coinState.coins.forEach((coin)=>{
                if (!existed.find((exitedCoin)=>exitedCoin.baseCoin===coin.baseCoin)){
                   existed.push(coin);
                }
            })
            return existed;
        }
        return [];
    },[coinState.coins])

  return (
    <div>
        
        {query.coin &&  <EntryGrid title={`${query.coin} : ${currentTicker?currentTicker?.bestBid:''}`}>
                    <InputForm onSubmit={(values)=>{
                        history.push(`sale-order-create-accounts?coin=${query.coin}&price=${values.price}&percentage=${values.percentage}`);
                    }}/>
        </EntryGrid>}
    </div>
  )
}

export default SpotBuyCreate