import React from 'react'
import { Col, Row, Form } from 'reactstrap'
import TextBox from 'components/Inputs/TextBox'
import Button from 'components/Button'
import { useForm } from 'react-hook-form'
import SelectBox from 'components/SelectBox'
import { useHistory } from 'react-router'

function InputForm({onSubmit}) {
    
    const {
        register,
        control,
        handleSubmit,
        formState: { errors },
    } = useForm()


    const submitData = async (values) => {
        onSubmit(values);    
    }


    return (
        <Form onSubmit={handleSubmit(submitData)}>
            <div className='py-3'>
                <div className='py-2'>
                    <h3>Open Limit Sale Order</h3>
                </div>
                <Row>
                    <Col md="6">
                        <label>Price</label>
                        <TextBox
                            className="form-control"
                            placeholder="Price"
                            type="text"
                            registerProps={register('price', {
                                required: 'Price is required',
                            })}
                            errors={errors.price}
                        />
                    </Col>
                    <Col md="6">
                        <label>Percentage</label>
                        <SelectBox
                            control={control}
                            className="form-control"
                            name="percentage"
                            rules={{ required: true }}
                            label="Percentage"
                            options={[
                                { label: '25%', value: '25' },
                                { label: '50%', value: '50' },
                                { label: '75%', value: '75' },
                                { label: '100%', value: '100' },
                            ]}
                        />
                    </Col>
                </Row>
                <Row style={{ display: 'flex', justifyContent: "center" }} className="py-3">
                    <Button type="submit">Submit</Button>
                </Row>
            </div>
        </Form>
    )
}

export default InputForm