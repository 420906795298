import React from 'react'
import Button from 'components/Button'
import List from './list'
import useSpotBuy from 'hooks/useSpotBuy'

function ReportTradingProfit() {

  const { state, fetchTradingProfit,fetchTradingProfitLogs } = useSpotBuy()

  const headers = ['No', 'Amount','Created At']

  async function loadData(){
    await fetchTradingProfit()
    await fetchTradingProfitLogs();
  }


  React.useEffect(() => {
    loadData();
  }, [])

  return (
    <div>
      <div className='card p-3'>
        <h3>Total Profit : {state.tradingProfit.amount}</h3>
      </div>
      <List
        headers={headers}
        total={state.total}
        data={state.tradingProfitLogs}
        title="Purchase Profit Logs"
        loading={state.loading}
        onSelect={(e) => {}}
        buttons={
          <>
            <Button
              onClick={() => {
                loadData();
              }}
            >
              Refresh
            </Button>
          </>
        }
      />
      
    </div>
  )
}

export default ReportTradingProfit