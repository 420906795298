import React from 'react'
import Button from 'components/Button'
import Table from 'components/Table'
import dayjs from 'dayjs'
import useAdmin from 'hooks/useAdmin'

function List({
  headers,
  data,
  onEdit,
  title,
  buttons,
  onConfirm,
  onCancel,
  onDelete,
  selectedData = [],
  onSelect,
  filter,
  loading,
  total,
  totalAmount,
}) {

  const { currentAdmin } = useAdmin();

  return (
    <Table
      title={title}
      headers={headers.map((e) => (
        <th scope="col" key={`thead-${Math.random()}`}>
          {e}
        </th>
      ))}
      loading={loading}
      filter={filter}
      buttons={buttons}
      total={total}
      totalAmount={totalAmount}
      rows={data?.map((e, index) => (
        <tr key={`table-row-${Math.random()}`}>
          <td>{index + 1}</td>
          <td>{e.id}</td>
          <td>{e.member.name}</td>
          <td>{e.member.code}</td>
          <td>{e.amount}</td>
          <td>{e.note}</td>
          <td>
            <span
              className={
                e.status === 1
                  ? 'text-success'
                  : e.status === 2
                    ? 'text-danger'
                    : 'text-primary'
              }
            >
              {e.status === 1
                ? 'Success'
                : e.status === 2
                  ? 'Cancel'
                  : 'Pending'}
            </span>
          </td>
          <td>{dayjs(e.createdAt).format('DD/MM/YYYY')}</td>
          <td>
            {currentAdmin?.role === "admin" && <>
              <Button size="sm" color="primary" onClick={() => onEdit(e)}>
                Detail
              </Button>
              {e.status === 0 && (
                <Button size="sm" color="success" onClick={() => onConfirm(e)}>
                  Confirm
                </Button>
              )}
              {e.status === 0 && (
                <Button size="sm" color="danger" onClick={() => onDelete(e)}>
                  Cancel
                </Button>)}
            </>}
          </td>
        </tr>
      ))}
    />
  )
}

export default List
