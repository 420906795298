import {  getData } from './apiService'
import { NotificationManager } from 'react-notifications'

export async function getAll() {
  try {
    let response = await getData('coins')
    return response.data
  } catch (e) {
    NotificationManager.error('Opps. Something wrong')
    return false
  }
}

