import React from 'react'
import EntryGrid from 'components/Inputs/EntryGrid'
import { Col, FormGroup, Row, Form } from 'reactstrap'
import Button from 'components/Button'
import TextBox from 'components/Inputs/TextBox'
import { useForm } from 'react-hook-form'
import useWithdraw from 'hooks/useWithdraw'
import useAdmin from 'hooks/useAdmin'

function InputForm({ title, data }) {
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm()

  const { confirmWithdraw } = useWithdraw()

  const { currentAdmin }=useAdmin();

  const submitData = async (values) => {
    const formData = new FormData()
    formData.append('image', values.image[0])
    await confirmWithdraw(data.id, formData)
    reset()
  }

  return (
    <EntryGrid title={title}>
      <Form onSubmit={handleSubmit(submitData)} encType="multipart/form-data">
        <div className="pt-2 pb-2">
          <Row>
            <Col>
              <FormGroup>
                <label>Receipt</label>
                <TextBox
                  className="form-control"
                  placeholder="Receipt"
                  type="file"
                  registerProps={register('image', {
                    required: 'Receipt is required',
                  })}
                  errors={errors.image}
                  accept="image/*"
                />
              </FormGroup>
            </Col>
          </Row>
          {currentAdmin?.role==="admin" && <div className="mt-5 text-center">
            <Button>Save</Button>
            <Button
              className="ml-2"
              onClick={() => {
                reset()
              }}
            >
              Reset
            </Button>
          </div>}
        </div>
      </Form>
    </EntryGrid>
  )
}

export default InputForm
