// nodejs library that concatenates classes
import classnames from 'classnames'
import useAdmin from 'hooks/useAdmin'
import { useEffect } from 'react';

// reactstrap components
import {
  Collapse,
  UncontrolledDropdown,
  Navbar,
  Nav,
  Container,
  DropdownMenu,
  DropdownToggle,
  DropdownItem,
} from 'reactstrap'

function AdminNavbar({ theme, sidenavOpen, toggleSidenav }) {

  const {state:adminState,checkLogin}=useAdmin();

  useEffect(()=>{
    checkLogin();
  },[])


  return (
    <>
      <Navbar className="navbar-top navbar-dark bg-primary navbar-expand border-bottom">
        <Container fluid>
          <div
            className={classnames(
              'pr-3 sidenav-toggler admin-navbar-toggle',
              { active: sidenavOpen },
              { 'sidenav-toggler-dark': theme === 'dark' }
            )}
            onClick={toggleSidenav}
          >
            <div className="sidenav-toggler-inner">
              <i className="sidenav-toggler-line" />
              <i className="sidenav-toggler-line" />
              <i className="sidenav-toggler-line" />
            </div>
          </div>
          <Collapse
            navbar
            isOpen={true}
            className="d-flex justify-content-between "
          >
            <h3 className="text-white mt-2 brand-text">Cpro Dashboard</h3>
            <Nav className=" ml-auto ml-md-0 mr-2" navbar>
              <UncontrolledDropdown>
                <DropdownToggle
                  style={{ background: 'none', border: 'none', zIndex: 0 }}
                >
                  <div className="navbar-dropdown">
                    <img
                      src="https://www.kindpng.com/picc/m/475-4750705_school-administrator-icon-png-transparent-png.png"
                      alt=""
                    />
                    <h6>{adminState.admin?.name} ({adminState.admin?.role})</h6>
                  </div>
                </DropdownToggle>
                <DropdownMenu>
                  <DropdownItem divider />
                  <DropdownItem
                    href="#pablo"
                    onClick={() => {
                      window.location = '/auth/login'
                    }}
                  >
                    Logout
                  </DropdownItem>
                </DropdownMenu>
              </UncontrolledDropdown>
            </Nav>
          </Collapse>
        </Container>
      </Navbar>
    </>
  )
}

AdminNavbar.defaultProps = {
  toggleSidenav: () => { },
  sidenavOpen: false,
  theme: 'dark',
}

export default AdminNavbar
