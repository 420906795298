import React from 'react'
import EntryGrid from 'components/Inputs/EntryGrid'
import { Col, FormGroup, Row, Form } from 'reactstrap'
import Button from 'components/Button'
import TextBox from 'components/Inputs/TextBox'
import { useForm } from 'react-hook-form'
import usePlan from 'hooks/usePlan'
import useAdmin from 'hooks/useAdmin'

function InputForm({ title, data }) {
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
    setValue,
  } = useForm()

  const { currentAdmin }=useAdmin();

  React.useEffect(() => {
    if (data) {
      setValue('name', data.name)
      setValue('price', data.price)
      setValue('maxLevel', data.maxLevel)
      setValue('totalClick', data.totalClick)
      setValue('minDeposit', data.minDeposit)
      setValue('maxDeposit', data.maxDeposit)
      setValue('bonus', data.bonus)
      setValue('duration', data.duration)
      setValue('referCount', data.referCount)
      setValue('color', data.color)
    }
  }, [data, setValue])

  const { storePlan, updatePlan } = usePlan()

  const submitData = async (values) => {
    data ? await updatePlan(data.id, values) : await storePlan(values)
    reset()
  }

  return (
    <EntryGrid title={title}>
      <Form onSubmit={handleSubmit(submitData)}>
        <div className="pt-2 pb-2">
          <Row>
            <Col md="4">
              <FormGroup>
                <label>Name</label>
                <TextBox
                  className="form-control"
                  placeholder="Name"
                  type="text"
                  registerProps={register('name', {
                    required: 'Name is required',
                  })}
                  errors={errors.name}
                />
              </FormGroup>
            </Col>

            <Col md="4">
              <FormGroup>
                <label>Price</label>
                <TextBox
                  className="form-control"
                  placeholder="Price"
                  type="number"
                  registerProps={register('price', {
                    required: 'Price is required',
                  })}
                  errors={errors.price}
                />
              </FormGroup>
            </Col>
            <Col md="4">
              <FormGroup>
                <label>Max Level</label>
                <TextBox
                  className="form-control"
                  placeholder="Max Level"
                  type="number"
                  registerProps={register('maxLevel', {
                    required: 'Max Level is required',
                  })}
                  errors={errors.maxLevel}
                  disabled={data ? true : false}
                />
              </FormGroup>
            </Col>
          </Row>
          <Row>
            <Col md="4">
              <FormGroup>
                <label>Min Deposit</label>
                <TextBox
                  className="form-control"
                  placeholder="Min Deposit"
                  registerProps={register('minDeposit', {
                    required: '"Min Deposit is required',
                  })}
                  errors={errors.minDeposit}
                  type="number"
                  step="any"
                />
              </FormGroup>
            </Col>
            <Col md="4">
              <FormGroup>
                <label>Max Deposit</label>
                <TextBox
                  className="form-control"
                  placeholder="Max Deposit"
                  registerProps={register('maxDeposit', {
                    required: '"Max Deposit is required',
                  })}
                  errors={errors.maxDeposit}
                  type="number"
                  step="any"
                />
              </FormGroup>
            </Col>
            <Col md="4">
              <FormGroup>
                <label>Bonus</label>
                <TextBox
                  className="form-control"
                  placeholder="Bonus"
                  default={0}
                  registerProps={register('bonus')}
                  errors={errors.bonus}
                  type="number"
                  step="any"
                />
              </FormGroup>
            </Col>
            <Col md="4">
              <FormGroup>
                <label>Duration</label>
                <TextBox
                  className="form-control"
                  placeholder="Duration"
                  default={0}
                  registerProps={register('duration')}
                  errors={errors.duration}
                  type="number"
                  step="any"
                />
              </FormGroup>
            </Col>
            <Col md="4">
              <FormGroup>
                <label>Refer Count</label>
                <TextBox
                  className="form-control"
                  placeholder="Refer Count"
                  default={0}
                  registerProps={register('referCount')}
                  errors={errors.referCount}
                  type="number"
                />
              </FormGroup>
            </Col>
            
          </Row>
          {currentAdmin?.role==="admin" && <div className="mt-5 text-center">
            <Button>Save</Button>
            <Button
              className="ml-2"
              onClick={() => {
                reset()
              }}
            >
              Reset
            </Button>
          </div>}
        </div>
      </Form>
    </EntryGrid>
  )
}

export default InputForm
