import React from 'react'
import { useHistory } from 'react-router'
import Button from 'components/Button'
import List from './list'
import FilterBox from './filter'
import EditDialog from '../edit/EditDialog'
import { useState } from 'react'
import ConfirmDialog from 'components/Dialogs/ConfirmDialog'
import useTransferAllowance from 'hooks/useTransferAllowance'
import useAdmin from 'hooks/useAdmin'

function TransferAllowance() {
  const router = useHistory()
  const [editData, setEditData] = React.useState(false)
  const [modalOpen, setModalOpen] = useState(false)
  const [showDelete, setShowDelete] = React.useState(false)
  const [mounted, setMounted] = useState(false)
  const headers = ['No', 'Name', 'Created_at', '', '']

  const { currentAdmin } = useAdmin();

  const {
    state,
    fetchTransferAllowance,
    deleteTransferAllowance,
  } = useTransferAllowance()

  React.useEffect(() => {
    if (!mounted) {
      setMounted(true)
      fetchTransferAllowance()
    }
  }, [mounted, fetchTransferAllowance])

  return (
    <>
      <List
        headers={headers}
        data={state.transferAllowances}
        title="Transfer Allowance"
        onDelete={(data) => {
          setEditData(data)
          setShowDelete(true)
        }}
        loading={state.loading}
        onEdit={(data) => {
          setEditData(data)
          setModalOpen(true)
        }}
        filter={<FilterBox />}
        onSelect={(e) => { }}
        buttons={
          <>
            {currentAdmin?.role === "admin" && <Button
              onClick={() => {
                router.push('transfer-allowance-create')
              }}
            >
              Add New
            </Button>}
            <Button
              onClick={() => {
                fetchTransferAllowance()
              }}
            >
              Refresh
            </Button>
          </>
        }
      />
      <EditDialog
        modalOpen={modalOpen}
        data={editData}
        onToggle={() => {
          setModalOpen(false)
          fetchTransferAllowance()
        }}
      />
      <ConfirmDialog
        modalOpen={showDelete}
        title={`Delete Transfer Allowance`}
        body={`Are you sure to delete ${editData?.amount}?`}
        onToggle={() => setShowDelete(false)}
        onConfirm={() => {
          setShowDelete(false)
          deleteTransferAllowance(editData.id)
          setMounted(false)
        }}
      />
    </>
  )
}

export default TransferAllowance
