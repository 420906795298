import React from 'react'
import Table from 'components/Table'
function List({
  headers,
  data,
  title,
  percentage,
  buttons,
  loading,
}) {

  return (
    <Table
      title={title}
      headers={headers.map((e) => (
        <th scope="col" key={`thead-${Math.random()}`}>
          {e}
        </th>
      ))}
      loading={loading}
      buttons={buttons}
      rows={data?.map((e, index) => (
        <tr key={`table-row-${Math.random()}`}>
          <td>{index + 1}</td>
          <td>{e.member.name} ({e.member.code})</td>
          <td>{e.wallet}</td>
          <td>{percentage && e.wallet+((e.wallet/100)*percentage)}</td>
          <td>{percentage && (e.wallet+((e.wallet/100)*percentage))-e.wallet}</td>
          <td>{percentage && `${percentage}%`}</td>
        </tr>
      ))}
    />
  )
}

export default List
