import React from 'react'
import Table from 'components/Table'

function List({
  headers,
  data,
  title,
  buttons,
  query,
  loading,

}) {
    const { coin, price, percentage } = query;
    const getUsabledAmount=(e)=>{
      return (e.balance/100)*percentage;
    }

  return (
    <Table
      title={title}
      headers={headers.map((e) => (
        <th scope="col" key={`thead-${Math.random()}`}>
          {e}
        </th>
      ))}
      loading={loading}
      buttons={buttons}
      rows={data?.map((e, index) => (
        <tr key={`table-row-${Math.random()}`}>
          <td>{index + 1}</td>
          <td>{e.account.name} ({e.account.code})</td>
          <td>{e.balance} {coin}</td>
          <td>{getUsabledAmount(e)} {coin}</td>
          <td>{(getUsabledAmount(e)*price)} USDT</td>
          <td>{(getUsabledAmount(e)*price)<10?<span className='text-danger'>Failed</span>:<span className='text-success'>OK</span>}</td>
        </tr>
      ))}
    />
  )
}

export default List
